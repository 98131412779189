<template>
	<div class="wrap">
		<myHead></myHead>
		<div class="wrap_body">
			<div class="center">
				<div class="ucenter_main">
					<ucMenu num="3"></ucMenu>
					<div class="right_con">
						<div class="goods_main" >
							<p class="fb">订单列表</p>
							<div class="select_wrap">
								<el-select class="w290" v-model="searchData.shop_type" placeholder="请选择" clearable filterable >
									<p slot="prefix" class="label_title">店铺类型</p>
									<el-option v-for="item in shopTypeList" :key="item.val" :label="item.text" :value="item.val">
									</el-option>
								</el-select>
								<el-select class="w290" v-model="searchData.shop_id" placeholder="请选择" clearable>
									<p slot="prefix" class="label_title">店铺名</p>
									<el-option v-for="item in arrShop" :key="item.key" :label="item.key" :value="item.val">
									</el-option>
								</el-select>
								<el-input class="w290" v-model="searchData.order_no" placeholder="请输入订单编号" clearable>
									<p slot="prefix" class="label_title">订单编号</p>
								</el-input>
								<el-input class="w290" v-model="searchData.goods_id" placeholder="请输入商品ID" clearable>
									<p slot="prefix" class="label_title">商品ID</p>
								</el-input>
<!--								<el-select class="w290" v-model="searchData.order_status" placeholder="请选择" clearable>-->
<!--									<p slot="prefix" class="label_title">订单状态</p>-->
<!--									<el-option v-for="item in arrStatus" :key="item.val" :label="item.text" :value="item.val">-->
<!--									</el-option>-->
<!--								</el-select>-->
								<el-select class="w290" v-model="searchData.after_sale_status" placeholder="请选择" clearable>
									<p slot="prefix" class="label_title">售后状态</p>
									<el-option v-for="item in arrAfterSales" :key="item.val" :label="item.text" :value="item.val">
									</el-option>
								</el-select>
								<el-select class="w290" v-model="searchData.seller_remark_stars" placeholder="请选择" clearable>
									<el-tooltip slot="prefix" class="item" effect="dark" content="可筛选订单号后带有标记的订单" placement="top">
										<p class="label_title">订单标记<i class="iconfont icon-shuoming"></i></p>
									</el-tooltip>
									<el-option v-for="item in arrStar" :key="item.val" :label="item.text" :value="item.val">
										<span style="float: left">{{ item.text }}</span>
										<span style="float: right;"><i :class="item.icon" :style="[{color:item.color}]"></i></span>
									</el-option>
								</el-select>
								<!-- <el-select class="w290" v-model="searchData.abnormal_order" placeholder="请选择" clearable>
									<p slot="prefix" class="label_title">异常订单</p>
									<el-option v-for="item in arrAbnormal" :key="item.val" :label="item.text" :value="item.val">
									</el-option>
								</el-select> -->
								<el-input class="w290" v-model="searchData.express_no" placeholder="请输入物流单号" clearable>
									<p slot="prefix" class="label_title">物流单号</p>
								</el-input>
								<el-input class=" w290" v-model="searchData.buyer_name" placeholder="请输入收货人姓名" clearable>
									<p slot="prefix" class="label_title">收货人</p>
								</el-input>
								<el-input class="w290" v-model="searchData.buyer_phone" placeholder="请输入收货人手机号" clearable>
									<p slot="prefix" class="label_title">手机号码</p>
								</el-input>
                <el-select class="w290" v-model="searchData.intercept_status" placeholder="请选择" clearable>
                  <p slot="prefix" class="label_title">拦截状态</p>
                    <el-option label="待拦截" :value="1"></el-option>
                    <el-option label="拦截中" :value="2"></el-option>
                    <el-option label="已拦截" :value="3"></el-option>
                </el-select>
								<el-date-picker class="w290"
									v-model="searchData.search_date"
									type="datetimerange"
									align="right"
									unlink-panels
									range-separator="至"
									start-placeholder="开始日期"
									end-placeholder="结束日期"
									value-format="yyyy-MM-dd HH:mm:ss"
									@onPick="datePick"
									:picker-options="pickerOptions"
									:default-time="['00:00:00', '23:59:59']">
								</el-date-picker>
								<el-button plain size="small" @click="getList()">查询</el-button>
								<el-button plain size="small" @click="resetSearch()">重置</el-button>
							</div>
							<div class="tabs_wrap">
								<p class="tab" :class="!searchData.order_status?'active':''" @click="changeStatus('')">全部订单</p>
							</div>
							<div class="totals_btn">
								<p class="totals">共{{page_data.total}}条</p>
                <el-button plain size="small" @click="onIntercept()">设置拦截</el-button>
								<p class="decrypt_msg" v-if="decrypt_state==2">{{decrypt_msg}} </p>
							</div>
							<div class="list_title">
								<label class="checkall">
									<el-checkbox v-model="checkAll" @change="bindCheckAll">全选</el-checkbox>
								</label>
								<p class="t1">货品信息</p>
								<p class="t2">售价/数量</p>
								<p class="t3">售后状态</p>
								<p class="t4">应付</p>
								<p class="t5">买家/收货人</p>
                <p class="t6">采购状态</p>
                <p class="t6">拦截状态</p>
								<p class="t7">操作</p>
							</div>
							<ul class="order_list">
								<el-checkbox-group v-model="checkList" @change="bindCheckList">
									<li v-for="(item,index) in list" :key="index">
										<div class="order_nums">
											<div class="check_box"><el-checkbox :label="item.order_id" :key="item.order_id" :checked="item.checked"></el-checkbox></div>
											<p class="num" v-if="item.shop_type==1 && item.dd_shop"><span class="shop_name">{{item.dd_shop.shop_name}}</span>抖店单号：{{item.dd_order_id}}<i class="iconfont icon-fuzhi" @click="bindCopy(item.dd_order_id)"></i></p>
											
											<p class="num" v-if="item.shop_type==2 && item.dd_shop"><span class="shop_name">{{item.dd_shop.shop_name}}</span>快店单号：{{item.dd_order_id}}<i class="iconfont icon-fuzhi" @click="bindCopy(item.dd_order_id)"></i></p>
											<p class="num" v-if="item.shop_type!=0 && !item.dd_shop"><span class="shop_name">店铺已删除</span>：{{item.dd_order_id}}<i class="iconfont icon-fuzhi" @click="bindCopy(item.dd_order_id)"></i></p>
											
											<p class="num" v-if="item.shop_type==0">平台单号：{{item.order_no}}<i class="iconfont icon-fuzhi" @click="bindCopy(item.order_no)"></i></p>
											<p class="date">下单时间：{{item.create_time}}</p>
											<p class="maoli">买家备注：{{item.buyer_words}}</p>
											<i v-if="item.dd_order_id!='0'" class="btn-remark el-icon-s-flag" @click="showRemark(item.order_id)" :style="{'color':item.seller_remark_starts_info.color}"></i>
										</div>
										<div class="order_info">
											<div class="order_product">
												<div class="product_item"  v-for="(product,pindex) in item.order_product">
													<div class="goods_info">
														<div class="img_box"><img class="coverimg" :src="product.product_pic" /></div>
														<div class="con_box">
															<p class="title">{{product.product_name}}</p>
															<p class="t1" v-if="product.goods_id">DDID：{{product.goods_id}}</p>
															<p class="t2">{{product.spec_value}}</p>
															<p class="t2">货号：{{product.goods_no}}</p>
															<p class="t2" v-if="product.supplier">供应商：{{product.supplier.nickname}}</p>
															<p class="t2" v-if="product.deliver_ageing">发货时效：{{product.deliver_ageing}}</p>
															<p class="t2" v-if="product.supplier_remark">
																<el-tooltip class="item" effect="dark" :content="product.supplier_remark" placement="top">
																	<span class="sp_remark">供应商备注：{{product.supplier_remark}}</span>
																</el-tooltip>
													</p>
															<p class="stock_msg" v-if="item.business.is_partner==1"><span>成本价：{{product.product_cost_price}}</span><span>利润：{{product.partner_profit}}</span></p>
															<p class="guanlian" @click="callCG(product)" v-if="item.purchase_status==1&&product.order_status==2">关联商品</p>
															<p class="stock_msg" v-if="product.stock_msg">{{product.stock_msg}}</p>
														</div>
													</div>
													<div class="goods_num">
														<p class="t1">{{product.item_amount}}</p>
														<p class="t2">x{{product.item_num}}</p>
													</div>
													<div class="goods_sales">
														<p>{{product.order_status_text}}</p>
														<p class="red" v-if="product.order_after_sales" @click="showAfterSaleInfo(product)">{{product.order_after_sales.text_part.aftersale_status_text}}</p>
														<p v-if="product.purchase_msg" class="red" @click="showPurchaseMsg(product)">{{product.purchase_msg}}</p>
													</div>
												</div>
												<p class="red" style="margin-left: 14px;">总利润：{{item.total_partner_profit}}</p>
											</div>
											<div class="goods_pay">
												<p class="t1">{{item.pay_type_text}}</p>
												<p class="t2">￥{{item.total_amount}}</p>
												<p class="t2">运费</p>
												<p class="t2">￥{{item.freight_price}}</p>
												<!-- <p class="t3">采购价</p>
												<p class="t4">￥{{item.order_amount}}</p> -->
											</div>
											<div class="goods_buyer">
												<p>{{item.order_address.mask_name}}</p>
												<p>{{item.order_address.mask_phone}}</p>
												<p>{{item.order_address.province}}{{item.order_address.city}}{{item.order_address.town}}{{item.order_address.mask_detail}}
												
													<el-tooltip class="item" effect="dark" content="点击查看" placement="top">
														<i class="iconfont el-icon-view" @click="showAddressInfo(item.order_id)"></i>
													</el-tooltip>
												</p>
											</div>
											<div class="goods_status">
												<p>{{item.purchase_status_text}}</p>
												<!-- <p v-if="item.supplier_order_delivery_count>0"><a :href="'/uc_order_detail?order_id='+item.order_id" class="details">查看物流</a></p> -->
											</div>
                      <div class="goods_status">
                        <p v-if="item.intercept_status">待拦截</p>
                        <p v-else>{{item.intercept_status==1?'待拦截':item.intercept_status==2?'拦截中':'已拦截'}}</p>
                        <!-- <p v-if="item.supplier_order_delivery_count>0"><a :href="'/uc_order_detail?order_id='+item.order_id" class="details">查看物流</a></p> -->
                      </div>
											<div class="goods_status">
												<p v-if="item.purchase_status==1&&(item.order_status==2||item.order_status==105)"><a href="javascript:;" class="details" @click="bindPurchase(item.order_id)">采购</a></p>
												<p v-if="item.purchase_status==2&&item.order_status==3"><a href="javascript:;" class="details" @click="bindReceipt(item.order_id)">确认收货</a></p>
												<p v-if="item.order_status==1"><a href="javascript:;" class="details" @click="payNow(item.order_id)">付款</a></p>
												<p><a :href="'/uc_order_detail?order_id='+item.order_id" class="details">订单详情</a></p>
                        <p><div @click="onIntercept(item.order_id)" class="details">设置拦截状态</div></p>

												<!-- <p v-if="item.is_decrypt_address==0 && searchData.order_status==1101" ><a href="javascript:;" @click="unDecrypt(item.order_id)" class="details">放弃解密</a></p>
												<p v-if="item.is_decrypt_address==2&&item.is_show_redecrypt==1"><a href="javascript:;" @click="reDecrypt(item.order_id)" class="details">再次解密</a></p> -->
											</div>
										</div>
										<div class="order_remarks">
											<div class="remarks_item">
												<p class="txt">商家备注：</p>
												<p class="remarks">{{item.seller_words}}</p>
											</div>
											<div class="remarks_item">
												<p class="txt">客服备注：</p>
												<p class="remarks">{{item.service_words}}
													<i class="iconfont el-icon-edit" @click="showRemark2(item.order_id)"></i>
												</p>
											</div>
											<div class="remarks_item abnormal" v-if="item.is_abnormal==1">
													异常订单
											</div>
										</div>
									</li>
								</el-checkbox-group>
								<li class="nodata" v-if="page_data.total == 0">暂无数据</li>
							</ul>
							<div class="turn_page">
								<Pagination :total="page_data.total" :page.sync="page_data.page" :limit.sync="page_data.limit" @pagination="getList" layout="total, sizes, prev, pager, next, jumper" :page-sizes="[10,50,100]" />
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- 收货地址信息弹窗 -->
			<orderAddress @close="dialog.addressDialog = false" :isLoading.sync="isLoading" :show.sync="dialog.addressDialog" :order_id.sync="order_id"></orderAddress>
			<!-- 标记备注弹窗 -->
			<orderRemark @close="dialog.remarkDialog = false" @complete="refresh()" :isLoading.sync="isLoading" :show.sync="dialog.remarkDialog" :order_id.sync="order_id"></orderRemark>
			<!-- 售后详情弹窗 -->
			<el-dialog title="售后详情" :visible.sync="dialog.aftersaleDialog" width="70%" center >
				<orderAfterSales @close="dialog.aftersaleDialog = false" @complete="refresh()" :isLoading.sync="isLoading" :show.sync="dialog.aftersaleDialog" :info.sync="order_product"></orderAfterSales>
			</el-dialog>
			<!-- 订单支付弹窗 -->
			<orderPay @close="dialog.payDialog = false" @success="$router.go(0)" :show.sync="dialog.payDialog" :order_id.sync="order_id"></orderPay>
			<!-- 同步订单弹窗 -->
			<syncOrder @close="dialog.syncOrderDialog = false" :isLoading.sync="isLoading" :show.sync="dialog.syncOrderDialog" ></syncOrder>
			<!-- 客服备注弹窗 -->
			<serviceRemark @close="dialog.remark2Dialog = false" @complete="refresh()" :isLoading.sync="isLoading" :show.sync="dialog.remark2Dialog" :order_id.sync="order_id"></serviceRemark>
      <el-dialog title="设置拦截" @close="cancel()" :visible.sync="dialog.interceptDialog" width="25%" center >
        <div class="opts_wrap">
          <div class="opts_line">
            <p class="txt">
              <span class="label">拦截状态：</span>
              <span class="val">
                <el-select class="w290" v-model="formIn.intercept_status" placeholder="请选择" clearable>
                  <p slot="prefix" class="label_title"></p>
                    <el-option label="待拦截" :value="1"></el-option>
                    <el-option label="拦截中" :value="2"></el-option>
                    <el-option label="已拦截" :value="3"></el-option>
                </el-select>
						</span>
            </p>
          </div>
          <div class="btn_wrap">
            <p class="btn" @click="bindSubmit()">确定</p>
            <p class="btn" @click="cancel()">关闭</p>
          </div>
        </div>
      </el-dialog>
		</div>
		<myFoot></myFoot>
		<connectGood ref="child" :dataArr="cg_data"></connectGood>
	</div>
</template>

<script>
import Vue from 'vue';
import myHead from '../../components/myHead/myhead';
import myFoot from '../../components/myFoot/myfoot';
import ucMenu from '../../components/ucenterMenu/ucmenu';
import orderAddress from '../../components/orderAddress/orderAddress';
import orderRemark from '../../components/orderRemark/orderRemark';
import serviceRemark from '../../components/serviceRemark/serviceRemark';
import connectGood from '../../components/connectGood/connectGood';
import orderAfterSales from '../../components/orderAfterSales/orderAfterSales';
import orderPay from '../../components/orderPay/orderPay';
import syncOrder from '../../components/syncOrder/syncOrder';
import Pagination from '../../components/pagination/pagination';
import { Loading } from 'element-ui';
import { mapActions,mapState,mapMutations } from "vuex";
export default {
	name: "ucenterOrders",
	data() {
		return {
			order_id: 0,
			options: [],
			pickerOptions: {
				shortcuts: [{
					text: '最近一周',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '最近一个月',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '最近三个月',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
						picker.$emit('pick', [start, end]);
					}
				}]
			},
			arrStatus:[],
			shopTypeList: [],
			arrStar:[],
			arrShop:[],
			arrAbnormal:[],
			arrAfterSales:[],
			checkAll: false,
			checkList: [],
			isLoading:false,
			list:[],
			page_data: {
				limit: 10,
				page: 1,
				total:0,
			},
			total_partner_profit:0,
			searchData: {},
			dialog: {
				addressDialog:false,
				aftersaleDialog:false,
				remarkDialog:false,
				remark2Dialog:false,
				payDialog:false,
				syncOrderDialog:false,
        interceptDialog:false
			},
			cg_data:"",
			order_product:[],
			is_decrypt:0,
			decrypt_state:1,
			decrypt_msg:'',
			loading_service_id: 0,
      formIn:{}
		}
	},
	components:{
		myHead,myFoot,ucMenu,orderAddress,connectGood,orderAfterSales,orderRemark,serviceRemark,orderPay,Pagination,syncOrder
	},
	created() {
		var query = this.$route.query
		if(query.status){
			this.searchData.order_status = Number(query.status)
		}
		if(query.after_sale){
			this.searchData.after_sale_status = Number(query.after_sale)
		}
		this.getList()
		this.initFilterData()
	},
	methods: {
		callCG(arr){//打开子组件店铺列表
			this.$refs.child.showConnectGood()
			this.cg_data = JSON.stringify(arr)
		},
		...mapActions({
			getOrderManageList:"ucenter/getOrderManageCutList",
			getOrderManageAddressInfo:"ucenter/getOrderManageAddressInfo",
			getOrderManageDetail:"ucenter/getOrderManageDetail",
			getOrderManageFilterData:"ucenter/getOrderManageFilterData",
			opOrderManageBatPurchase:"ucenter/opOrderManageBatPurchase",
      opOrderManageBatAbnormal:"ucenter/opOrderManageBatAbnormal",
			opOrderManageBatIntercept:"ucenter/opOrderManageBatIntercept",
			opOrderManageReceipt:"ucenter/opOrderManageReceipt",
			opDdShopRefreshDecrypt:"ucenter/opDdShopRefreshDecrypt",
			opUnDecrypt: "ucenter/opUnDecrypt",
			opReDecrypt: "ucenter/opReDecrypt"
		}),
		initFilterData(){
			this.getOrderManageFilterData({data:{},success:(res)=>{
				if(res.code===200){
					// console.log('get goods filter data',res)
					this.arrStatus = res.data.arr_status
					this.arrStar = res.data.arr_star
					this.arrAbnormal = res.data.arr_abnormal
					this.arrAfterSales = res.data.arr_after_sales
					this.arrShop = res.data.arr_shop
					this.shopTypeList = res.data.shop_types
				}
			}})
		},
		//重置搜索
		resetSearch(){
			this.searchData = {}
			this.checkAll = false
			this.checkList = []
			this.getList()
		},
		//刷新列表
		refresh(){
			this.page_data.page = 1
			this.checkAll = false
			this.checkList = []
			this.getList()
		},
		changeStatus(val){
			this.searchData.order_status = val
			this.refresh()
		},
		getList(showLoading = true){
			if(showLoading) this.isLoading = true
			let loadingInstance = Loading.service({ fullscreen: true });

			let param = {limit:this.page_data.limit,page:this.page_data.page}
			Object.assign(param, this.searchData)
			console.log(param)
			this.getOrderManageList({data:param,success:(res)=>{
				if(res.code===200){
					// console.log('get order list',res)
					this.list = res.data.data
					this.page_data.total = res.data.total
					this.is_decrypt = res.data.is_decrypt
					this.decrypt_state = res.data.decrypt_state
					this.decrypt_msg = res.data.decrypt_msg
					this.total_partner_profit = res.data.total_partner_profit
				} else {
					this.$message({message: res.msg,type: 'error',});
				}
				this.isLoading = false
				loadingInstance.close()
			}})
		},
    // 批量设置拦截
    onIntercept(val){
      let that = this
      if(!val){
        let checkList = this.checkList
        if(checkList.length == 0){
          that.$message({
            type: 'warning',
            message: '请选择执行操作的订单'
          })
          return
        }
      }else{
        that.checkList = [val]
      }
      that.dialog.interceptDialog=true
    },
    cancel(){
      this.formIn = {}
      this.dialog.interceptDialog=false
    },
    // 设置拦截
    bindSubmit() {
      let that = this
      if(!that.formIn.intercept_status){
        that.$message({
          type: 'warning',
          message: '请选择拦截状态'
        })
        return
      }
      let checkList = this.checkList
      let param = {ids:checkList,intercept_status:that.formIn.intercept_status}
      that.opOrderManageBatIntercept({data:param,success:(res)=>{
          if(res.code == 200){
            that.$message({
              type: 'success',
              message: res.msg
            })
            that.dialog.interceptDialog=false
            that.refresh()
          }else{
            that.$message({
              type: 'error',
              message: res.msg
            })
          }
        }})
    },
    showAddressInfo(id){
			this.order_id = id
			this.dialog.addressDialog = true
		},
		showRemark(id){
			this.order_id = id
			this.dialog.remarkDialog = true
		},
		showRemark2(id){
			this.order_id = id
			this.dialog.remark2Dialog = true
		},
		showAfterSaleInfo(order_product){
			this.order_product = order_product
			this.dialog.aftersaleDialog = true
		},
		showPurchaseMsg(order_product){
			this.$alert(order_product.purchase_msg, '采购状态', {
				type: 'warning',
				center: true
			});
		},
		bindCopy(text){
			var str = ''
			var input = document.createElement("input"); // 创建input对象
			input.value = text; // 设置复制内容
			document.body.appendChild(input); // 添加临时实例
			input.select(); // 选择实例内容
			document.execCommand("Copy"); // 执行复制
			document.body.removeChild(input); // 删除临时实例
			this.$message({
				message: '复制成功',
				type: 'success'
			});
			
		},
		//全选
		bindCheckAll(){
			let checkAll = this.checkAll
			let list = this.list
			let checkList = []
			if(checkAll){
				for(var i in list){
					if(checkAll){
						checkList.push(list[i].order_id)
					}
				}
			}
			this.checkList = checkList
		},
		//单项选
		bindCheckList(){
			let checkList = this.checkList
			if(checkList.length == this.list.length){
				this.checkAll = true
			}else{
				this.checkAll = false
			}
		},
		//确认收货
		bindReceipt(order_id){
			let that = this
			let param = {order_id:order_id}
			that.$confirm('确认收货操作?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then((res) => {
				that.opOrderManageReceipt({data:param,success:(res)=>{
					if(res.code == 200){
						that.$message({
							type: 'success',
							message: res.msg
						})
						that.refresh()
					}else{
						that.$message({
							type: 'error',
							message: res.msg
						})
					}
				}})
			}).catch((res) => {
				console.log(res)
			});
		},
		//立即采购
		bindPurchase(order_id){
			this.postPurchcase(order_id)
		},
		

		//采购请求
		postPurchcase(ids){
			let that = this
			let param = {ids:ids}
			that.$confirm('确认采购操作?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then((res) => {
				that.opOrderManageBatPurchase({data:param,success:(res)=>{
					if(res.code == 200){
						that.$message({
							type: 'success',
							message: res.msg
						})
						that.refresh()
					}else{
						that.$message({
							type: 'error',
							message: res.msg
						})
					}
				}})
			}).catch((res) => {
				console.log(res)
			});
		},
		//批量标记异常
		bindBatAbnormal(val){
			let that = this
			let checkList = this.checkList
			if(checkList.length == 0){
				that.$message({
					type: 'warning',
					message: '请选择执行操作的订单'
				})
				return
			}
			let param = {ids:checkList,state:val}
			that.opOrderManageBatAbnormal({data:param,success:(res)=>{
				if(res.code == 200){
					that.$message({
						type: 'success',
						message: res.msg
					})
					that.refresh()
				}else{
					that.$message({
						type: 'error',
						message: res.msg
					})
				}
			}})
		},
		//订单导出
		orderExport(){
			if(this.isLoading) return
			this.isLoading = true
			let loadingInstance = Loading.service({ fullscreen: true });
			let param = {limit:this.page_data.limit,page:this.page_data.page,is_export:1,order_ids:this.checkList}
			Object.assign(param, this.searchData)
			this.getOrderManageList({data:param,success:(res)=>{
				this.isLoading = false
				if(res.code===200){
					// window.open(res.data) 
					window.location.href = res.data 
				} else {
					this.$message({message: res.msg,type: 'error',});
				}
				loadingInstance.close()
			}})
		},
		//立即付款
		payNow(order_id){
			this.order_id = Number(order_id)
			this.dialog.payDialog = true
		},
		//提额成功更新店铺状态
		refreshDecrypt(){
			this.opDdShopRefreshDecrypt({data:{},success:(res)=>{
				if(res.code == 200){
					this.$message({message:res.msg,type:'success'})
					this.refresh()
				}else{
					this.$message({message:res.msg,type:'error'})
				}
			}})
		},
		datePick(){
			console.log(111)
		},
		showDecryptBox(){
			this.$confirm('我已申请提额', '提示', {
				confirmButtonText: 'YES',
				cancelButtonText: 'NO',
				type: 'info',
				center: true
			}).then(() => {
				this.$message({
					type: 'success',
					message: '操作成功!'
				});
			}).catch(() => {
				this.$message({
					type: 'info',
					message: '取消操作'
				});          
			});
		},
		//放弃解密
		unDecrypt(ids){
			var that = this
			this.$confirm('确认放弃解密？', '提示', {
				confirmButtonText: 'YES',
				cancelButtonText: 'NO',
				type: 'info',
				center: true
			}).then(() => {
				
				that.opUnDecrypt({data:{ids:ids},success:(res)=>{
					if(res.code == 200){
						that.$message({
							type: 'success',
							message: res.msg
						})
						that.refresh()
					}else{
						that.$message({
							type: 'error',
							message: res.msg
						})
					}
				}})
				
			})
		},
		//批量放弃解密
		batUnDecrypt(){
			let checkList = this.checkList
			let ids = checkList.join(',')
			this.unDecrypt(ids)
		},
		//恢复解密
		reDecrypt(ids){
			var that = this
			this.$confirm('确认恢复解密？', '提示', {
				confirmButtonText: 'YES',
				cancelButtonText: 'NO',
				type: 'info',
				center: true
			}).then(() => {
				that.opReDecrypt({data:{ids:ids},success:(res)=>{
					if(res.code == 200){
						that.$message({
							type: 'success',
							message: res.msg
						})
						that.refresh()
					}else{
						that.$message({
							type: 'error',
							message: res.msg
						})
					}
				}})
				
			})
		},
		//批量恢复解密
		batReDecrypt(){
			let checkList = this.checkList
			let ids = checkList.join(',')
			this.reDecrypt(ids)
		},
	}
}
</script>

<style scoped>
.wrap .wrap_body{display: flex;flex-direction: column;}
.center{display: flex;flex: 1;}
.ucenter_main{padding: 30px 0 40px;display: flex;justify-content: space-between;flex: 1;}
.right_con{width: 980px;background: #fff;}
.goods_main{padding: 36px 40px 46px;min-height: 70vh;}
.select_wrap{margin-top: 24px;display: flex;flex-wrap: wrap;}
.select_wrap .mr{margin-right: 15px;}
.select_wrap .w290{width: 290px;margin-top: 14px;margin-right: 15px;}
.select_wrap .w290:nth-child(3n){margin-right: 0;}
.select_wrap .label_title{width: 76px;height: 100%;font-size: 12px;color: #999;display: flex;align-items: center;padding-left: 6px;}
.select_wrap .label_title .icon-shuoming{font-size: 12px;color: #999;margin-left: 2px;}
.tabs_wrap{width: 100%;height: 60px;border-bottom: 1px solid #eee;display: flex;}
.tabs_wrap .tab{height: 100%;padding: 30px 16px 0;position: relative;line-height: 1;cursor: pointer;font-size: 14px;color: #666;box-sizing: border-box;display: flex;justify-content: center;align-items: center;}
.tabs_wrap .tab.active{font-weight: bold;color: var(--red);}
.tabs_wrap .tab.active::after{content: '';width: 100%;height: 3px;background: var(--red);position: absolute;left: 0;bottom: 0;z-index: 2;}
.tabs_wrap .tab .iconfont{font-size: 14px;color: #1966ff;margin-left: 4px;}
.totals_btn{margin-top: 14px;display: flex;align-items: center;}
.totals_btn .totals{font-size: 12px;color: #333;padding-right: 16px;margin-right: 16px;line-height: 1;border-right: 1px solid #ccc;}
.total_profit{margin-left: 10px;font-size: 14px;}
.totals_btn .decrypt_msg{font-size: 14px;color: var(--red);padding-left:10px;}
.list_title{width: 100%;height: 50px;background: #f9f9f9;margin-top: 10px;display: flex;align-items: center;font-size: 12px;color: #666;line-height: 1;}
.list_title .checkall{margin-left: 20px;}
.list_title .t1{margin-left: 10px;width: 182px;}
.list_title .t2{margin: 0 20px;width: 60px;text-align: right;}
.list_title .t3{margin: 0 10px;width: 90px;text-align: left;}
.list_title .t4{margin-left: 20px;width: 60px;text-align: right;}
.list_title .t5{margin: 0 20px;width: 150px;text-align: left;}
.list_title .t6{margin: 0 20px 0 0;flex: 1;text-align: left;}
.list_title .t7{margin: 0 20px 0 0;flex: 1;text-align: right;}
.order_list li{margin-top: 10px;font-size: 12px;}
.order_list li .order_nums{width: 100%;height: 40px;background: #fff6f7;display: flex;align-items: center;position: relative;}
.order_list li .order_nums .btn-remark{position: absolute;right: 20px;cursor: pointer;font-size: 14px;}
.order_list li .order_nums .check_box{margin-left: 20px;}
.order_list li .order_nums .num{display: flex;align-items: center;margin-left: 12px;color: #666666;}
.order_list li .order_nums .num .iconfont{cursor: pointer;font-size: 14px;color: #666;margin-left: 8px;}
.order_list li .order_nums .date{margin-left: 26px;color: #666666;}
.order_list li .order_nums .maoli{margin-left: 30px;color: #666666;}
.order_list li .order_info{padding: 20px 0;border-bottom: 1px dashed #eee;display: flex;align-items: flex-start;line-height: 1;}
.order_list li .order_info .order_product{display: felx;flex-direction: column;}
.order_list li .order_info .order_product .product_item{display: flex;padding-bottom: 20px;}
.order_list li .order_info .order_product .product_item:last-child{padding-bottom: 0;}
.order_list li .order_info .goods_info{width: 242px;display: flex;margin-left: 20px;}
.order_list li .order_info .goods_info .img_box{width: 48px;height: 48px;background-color: #eeeeee;border-radius: 6px;overflow: hidden;}
.order_list li .order_info .goods_info .con_box{flex: 1;width: 0;margin-left: 10px;}
.order_list li .order_info .goods_info .con_box .title{line-height: 16px;display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 2;overflow: hidden;}
.order_list li .order_info .goods_info .con_box .t1{color: #999999;margin-top: 12px;line-height: 14px;}
.order_list li .order_info .goods_info .con_box .t2{color: #999999;margin-top: 8px;}
.order_list li .order_info .goods_info .con_box .t2 .sp_remark{display: inline-block;max-width: 100%;text-overflow: ellipsis;overflow: hidden;white-space: nowrap;color: #f0b92c;}
.order_list li .order_info .goods_info .con_box .stock_msg{color: var(--red);margin-top: 8px;display: flex;justify-content: space-between;}
.order_list li .order_info .goods_info .con_box .guanlian{color: #1966ff;margin-top: 8px;cursor: pointer;}
.order_list li .order_info .goods_info .con_box .guanlian:hover{text-decoration: underline;}
.order_list li .order_info .goods_num{margin: 0 20px;width: 60px;text-align: right;}
.order_list li .order_info .goods_num .t1{color: #333333;}
.order_list li .order_info .goods_num .t2{color: #999999;margin-top: 10px;}
.order_list li .order_info .goods_sales{margin: 0 10px;width: 90px;text-align: left;}
.order_list li .order_info .goods_sales>p{line-height: 18px;color: #333;}
.order_list li .order_info .goods_sales>p.red{color: var(--red);cursor: pointer;}
.order_list li .order_info .goods_pay{margin-left: 20px;width: 60px;text-align: right;}
.order_list li .order_info .goods_pay .t1{color: #333333;}
.order_list li .order_info .goods_pay .t2{color: #333333;margin-top: 10px;}
.order_list li .order_info .goods_pay .t3{color: #666666;margin-top: 20px;}
.order_list li .order_info .goods_pay .t4{color: #666666;margin-top: 10px;}
.order_list li .order_info .goods_buyer{margin: 0 20px;width: 150px;text-align: left;}
.order_list li .order_info .goods_buyer>p{line-height: 18px;color: #666;}
.order_list li .order_info .goods_buyer>p .iconfont{cursor: pointer;font-size: 12px;color: #666;margin-left: 8px;}
.order_list li .order_info .goods_status{margin: 0 20px 0 0;flex: 1;text-align: left;}
.order_list li .order_info .goods_status:last-child{text-align: right;}
.order_list li .order_info .goods_status>p{margin-bottom: 10px;}
.order_list li .order_info .goods_status .details{color: #f0413e;cursor: pointer;}
.order_list li .order_info .goods_status .details:hover{text-decoration: underline;}
.order_list li .order_info .goods_status p>a{color: #f0413e;cursor: pointer;}
.order_list li .order_info .goods_status p>a:hover{text-decoration: underline;}
.order_list li .order_remarks{padding: 16px 0;border-bottom: 1px solid #eee;display: flex;align-items: center;justify-content: space-between;}
.order_list li .order_remarks .remarks_item{display: flex;flex: 1;}
.order_list li .order_remarks .remarks_item.abnormal{width: 100px;flex: unset;text-align: right;color: #f0b92c;display: flex;align-items: center;justify-content: flex-end;padding-right: 10px;font-weight: bold}
.order_list li .order_remarks .txt{font-size: 12px;color: #999999;line-height: 18px;width: 78px;box-sizing: border-box;padding-left: 10px;}
.order_list li .order_remarks .remarks{flex: 1;width: 0;font-size: 12px;color: #333;line-height: 18px;}
.turn_page{display: flex;justify-content: flex-end;margin-top: 30px;}
.order_list li .order_remarks .remarks i{cursor: pointer;}
.order_list li .check_box{margin-left: 20px;height: 48px;display: flex;align-items: center;}
/deep/.order_list li .check_box .el-checkbox__label{display: none !important;}
.order_list .shop_name{max-width: 80px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;display: inline-block;margin-right: 5px;}
</style>
<style>
.has-time .el-button{padding: 7px 15px;}
.select_wrap .el-button{margin-top: 14px;}
.select_wrap .el-input--prefix .el-input__inner{padding-left: 76px !important;height: 34px !important;line-height: 34px !important;font-size: 12px !important;}
.el-select .el-input.is-focus .el-input__inner ,.el-select .el-input__inner:focus{border-color: var(--red);}
.el-select-dropdown__item.selected{color: var(--red);}
.select_wrap .el-input--prefix .el-input__inner:focus{border-color: var(--red);}

.el-date-editor .el-range-separator{line-height: 26px;}
.el-date-editor{width:290px !important;height: 34px !important;line-height: 34px !important;font-size: 12px !important;margin-top: 14px;}
.el-range-editor.is-active, .el-range-editor.is-active:hover{border-color: var(--red);}
.el-date-table td.end-date span, .el-date-table td.start-date span{background-color: var(--red);}
.el-date-table td.today span{color: var(--red);}
.el-picker-panel__icon-btn:hover,.el-date-table td span:hover,.el-date-table td div:hover,.el-date-table td.available:hover,.el-picker-panel__shortcut:hover{color: var(--red);}

.el-button{color: #333;border-color: #ddd;}
.el-button--small{padding: 7px 15px;}
.el-checkbox{color: #666;display: flex;align-items: center;}
.el-checkbox__inner{border-color: #ddd;width: 16px;height: 16px;}
.el-checkbox__input{display: flex;}
.el-checkbox__label{font-size: 12px;line-height: 1;}
.el-checkbox__inner::after{height: 9px;left: 5px;}
.el-checkbox__input.is-indeterminate .el-checkbox__inner::before{height: 4px;}

/*red border color*/
.el-button.is-plain:focus, .el-button.is-plain:hover ,.el-checkbox__inner:hover ,.el-checkbox__input.is-focus .el-checkbox__inner,.el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner, .el-pagination__jump .el-input__inner:focus{border-color: var(--red);}

/*red color*/
.el-button.is-plain:focus, .el-button.is-plain:hover, .el-checkbox__input.is-checked+.el-checkbox__label, .el-pager li.active ,.el-pagination button:hover ,.el-pager li:hover{color: var(--red);}

/*red background color*/
.el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner{background-color: var(--red);}
.el-input__icon{line-height:unset;}
.el-button--primary,.el-button--primary:hover{background-color: var(--red) !important;border-color: var(--red) !important;color: #fff !important;}
.el-button:hover{border-color: var(--red) !important;background-color: rgba(240,65,62,0.1) !important;color: var(--red) !important;}

.opts_wrap{padding-top: 14px;}
.opts_wrap .opts_line{margin-top: 20px;display: flex;align-items: center;}
.opts_line .txt{width: 100%;font-size: 16px;display: flex;align-items: center;}
.opts_line .txt .label{min-width: 80px;display: inline-block;}
.opts_wrap .btn_wrap{margin: 40px auto 0 auto;display: flex;align-items: center;justify-content: center;}
.opts_wrap .btn_wrap .btn{width: 80px;height: 34px;border-radius: 4px;display: flex;justify-content: center;align-items: center;cursor: pointer;box-sizing: border-box;font-size: 14px;}
.opts_wrap .btn_wrap .btn:nth-child(1){background: var(--red);color: #fff;margin-right: 14px;}
.opts_wrap .btn_wrap .btn:nth-child(2){background: #fff;color: #333;border: 1px solid #ddd;margin-right: 14px;}
.ml8{margin-left: 8px;}
.opts_wrap .btn_wrap .btn:nth-child(3){background: #fff;color: #333;border: 1px solid #ddd;}
.w80{width: 80px;}
.el-icon-document-copy{cursor: pointer;}

.el-input--suffix .el-input__inner{padding: 0 0 0 12px !important;font-size: 16px;}
/*red border color*/
.el-input__inner:focus ,.el-select .el-input.is-focus .el-input__inner ,.el-select .el-input__inner:focus ,.el-radio__input.is-checked .el-radio__inner ,.el-radio__inner:hover{border-color: var(--red);}

/*red color*/
.el-select-dropdown__item.selected ,.el-radio__input.is-checked+.el-radio__label{color: var(--red);}

/*red background color*/
.el-radio__input.is-checked .el-radio__inner{background-color: var(--red);}
.el-date-editor{margin-top: unset;}
</style>
